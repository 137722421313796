/*!
 * Dialog
 *
 * @author      Roland Franssen <franssen.roland@gmail.com>
 * @license     MIT
 * @version     2.1
 **/

let last_style;
let last_width;

let Dialog = function(){this.initialize.apply(this, arguments);}; // eslint-disable-line no-shadow
let Dialogs = { //eslint-disable-line no-shadow
    Lang:{
        close:   '&nbsp;&times;&nbsp;',
        prev:    '&laquo; Previous',
        next:    'Next &raquo;',
        loading: 'Loading...',
        ok:      'OK',
        yes:     'Yes',
        no:      'No'
    },
    Default:{
        handle:         null,                    // css rule | element | null
        autoOpen:       false,                   // true | false
        background:     ['#000', '#fff'],        // array
        width:          'auto',                  // auto | max | integer
        height:         'auto',                  // auto | max | integer
        minWidth:       null,                    // null | pixel value
        minHeight:      null,                    // null | pixel value
        innerScroll:    true,                    // true | false
        innerScrollOnlyY: false,                 // true | false
        opacity:        0.75,                     // float | false
        margin:         10,                      // integer
        padding:        10,                      // integer
        title:          null,                    // string | null
        className:      null,                    // string | null
        topClassName:   null,                    // string | null
        content:        null,                    // string | element | array | object | function
        iframe:         null,                    // string | null
        target:{
          id:           null,                    // string | null
          auto:         true                     // true | false
        },
        ajax:{
          url:          null,                    // string | null
          jsonTemplate: null,                    // interpolation template string | null
          options:      {}                       // default ajax options
        },
        close:{
          link:         true,                    // true | false
          esc:          true,                    // true | false
          overlay:      true                     // true | false
        },
        afterOpen:      () => {}, // function
        afterClose:     () => {}, // function
        afterClick:     () => {}, // function
        afterIframeLoad:() => {}  // function
    }
};


Object.assign(Dialogs, {
    _exec:false,
    _open:false,
    _elements: {
        overlay: ['div', 'dialog-overlay', 'fixed'],
        container: ['div', 'dialog-container', 'fixed'],
        content: ['div', 'dialog-content'],
        loading: ['div', 'dialog-loading'],
        top: ['div', 'dialog-top'],
        bottom: ['div', 'dialog-bottom'],
        title: ['span', 'dialog-title'],
        close: ['a', 'dialog-close'],
        next: ['a', null, 'next'],
        prev: ['a', null, 'prev'],
        curr: ['span', null, 'curr']
    },

    view:function(){
        var view = document.documentElement,
            dim  = {width: view.offsetWidth, height: view.offsetHeight},
            data = {width:dim.width, height:dim.height};

        // limit size in mobile mode
        data.width = 0.96 * data.width;
        data.height = 0.93 * data.height;
        return data;
    },
    elm:function(elm){
        return Dialogs._elements[elm];
    },
    load:function(domready){
        if(!!Dialogs._exec) return; // eslint-disable-line no-extra-boolean-cast
        Dialogs._exec = true;
        let e = Dialogs._elements;
        for(let x in e){
            const d = e[x];
            let el = document.createElement(d[0]);
            el.style.display = 'none';
            if(d[1]) el.id = d[1];
            if(d[2]) el.className = d[2];
            if(d[0] == 'a') el.href = 'javascript:;'; // eslint-disable-line no-script-url
            if(Dialogs.Lang[x]) el.innerHTML = Dialogs.Lang[x];
            Dialogs._elements[x] = el;
        }

        const load = function(){
            const els = Dialogs._elements;
            const body = document.querySelector('body');
            els['bottom'].insertBefore(els['prev'], null);
            els['bottom'].insertBefore(els['curr'], null);
            els['bottom'].insertBefore(els['next'], null);
            els['top'].insertBefore(els['title'], null);
            els['top'].insertBefore(els['close'], null);
            els['container'].insertBefore(els['top'], null);
            els['container'].insertBefore(els['content'], null);
            els['container'].insertBefore(els['bottom'], null);
            body.insertBefore(els['overlay'], null);
            body.insertBefore(els['container'], null);
        };
        if(document.readyState != 'loading'){
            load.call();
        } else {
            document.addEventListener("DOMContentLoaded", load);
        }
    },
    close:function(){
        Dialogs.elm('title').innerHTML = '';
        Dialogs.elm('curr').innerHTML = '';
        Dialogs.elm('content').innerHTML = '';
        for(var x in Dialogs._elements) {
            if (Dialogs._elements[x] && Dialogs._elements[x].style) {
                Dialogs._elements[x].style.display = 'none';
            }
        }
        if (Dialogs.elm('container')?.style) {
            Dialogs.elm('container').style.left = '-99999px';
            Dialogs.elm('container').style.top = '-99999px';
        }
        Dialogs._open = false;
        last_style = '';
    },
    alert:function(s){
        const o = document.createElement('input');
        o.value = Dialogs.Lang.ok;
        o.type = 'button';
        const a = new Dialog({
                className:'alert',
                close:{link:false, esc:true},
                padding:20,
                content:function(){
                    o.addEventListener('click', Dialogs.close);
                    return [s, '<br /><br />', o];
                },
                afterOpen:function(){
                    o.focus();
                }
            });
        a.open();
    },
    confirm:function(s, y_call, n_call){
        const y = document.createElement('button');
        y.type = 'button';
        y.value = Dialogs.Lang.yes;
        y.innerHTML = '<span class="label">' + Dialogs.Lang.yes + '</span>';
        y.className = 'wc-button wc-button--primary';
        const n = document.createElement('button');
        n.value = Dialogs.Lang.no;
        n.innerHTML = '<span class="label">' + Dialogs.Lang.no + '</span>';
        n.type = 'button';
        n.className = 'wc-button wc-button--secondary';
        const c = new Dialog({
                className:'confirm',
                close:{link:false},
                padding:20,
                content:function(){
                    y.addEventListener('click', function(){
                        if(typeof y_call == 'function') y_call();
                        else Dialogs.close();
                    });
                    n.addEventListener('click', function(){
                        if(typeof n_call == 'function') n_call();
                        else Dialogs.close();
                    });
                    const buttons = document.createElement('div');
                    buttons.style.display = 'flex';
                    buttons.style.gap = '16px';
                    buttons.style.justifyContent = 'center';
                    buttons.insertBefore(n, null);
                    buttons.insertBefore(y, null);
                    return [s, buttons];
                },
                afterOpen:function(){
                    y.focus();
                }
            });
        c.open();
    }
});
Dialog.prototype = {
    initialize:function(opt){
        this.opt = Object.assign(Object.assign({},Dialogs.Default), opt || {});
        let c = this.opt.content;
        if(typeof c == 'function')
            Object.assign(this.opt, {content:c()});
        c = this.opt.content;

        if(typeof this.opt.target.id == 'string' || this.opt.target.id instanceof HTMLElement ){
            let b = this.opt.target.id;
            if(typeof this.opt.target.id == 'string') {
                b = document.getElementById(this.opt.target.id);
            }
            Object.assign(this.opt, {content:b.innerHTML});
            if(this.opt.target.auto){
                var a = (/#(.+)$/).exec(window.location);
                if(Array.isArray(a) && typeof (a[1]) == 'string'){
                    let a1 = a[1].split(',');
                    a = a1[a1.length - 1];
                    let thisDlg = this;
                    if(a == b.id){window.setTimeout(thisDlg.open.bind(thisDlg), 1000);}
                }
            }
        }else if(c && !Array.isArray(c) && !(c instanceof HTMLElement) && typeof c == 'object')
            this.steps = {
                i:0,
                k:c.keys(),
                v:c.values(),
                m:c.size()
            };
        this.attachEvents();
        if(this.opt.autoOpen) this.open();
    },
    exec:function(bool){
        return Dialogs._open == this._open && (Dialogs.elm('overlay').style.display != 'none') && bool;
    },
    attachEvents:function(){
        window.addEventListener('resize', this.setDimensions.bind(this));

        var handles = [];
        if(this.opt.handle instanceof HTMLElement){
            handles.push(this.opt.handle);
        } else if(Array.isArray(this.opt.handle)) {
            this.opt.handle.forEach(handle => { handles.push(handle); });
        } else if(typeof this.opt.handle == 'string') {
            handles = document.querySelectorAll(this.opt.handle);
        }
        handles.forEach(handle => {
            handle.style.display = 'block';
            // prevents the handle being applied every time dialogs is initialised.
            if(!handle.dialog_listener) {
                handle.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(typeof this.opt.afterClick == 'function') this.opt.afterClick(e);
                    this.open();
                });
                handle.dialog_listener = true;
            }
        });

        Dialogs.elm('close').addEventListener('click', () => {
            if(this.exec(this.opt.close.link)) this.close();
        });
        Dialogs.elm('overlay').addEventListener('click', () => {
            if(this.exec(this.opt.close.overlay)) this.close();
        });
        document.addEventListener('keyup', e =>{
            if(this.exec(this.opt.close.esc && (e.key) == "Escape")) this.close();
        });

        if(this.steps){
            [Dialogs.elm('prev'), Dialogs.elm('next')].forEach( e => {
                e.addEventListener('click', this.setSteps.bind(this));
            });
            document.addEventListener('keydown', e =>{
                var c = e.key;
                if(this.exec((c == "ArrowLeft") || (c == "ArrowRight"))) this.setSteps(e);
            });
        }
    },
    setAuto:function(){
        this.auto = {max:0};
        const t = Dialogs.elm('title'), c = Dialogs.elm('close');

        ['top', 'content', 'bottom'].forEach( b => {
            const e = Dialogs.elm(b);
            if(e.style.display == 'none') this.auto[b] = {width:0,height:0};
            else{
                e.setAttribute('style', 'display:inline;float:left;overflow:visible;white-space:nowrap');
                this.auto[b] = {width: e.offsetWidth, height: e.offsetHeight};
                e.setAttribute('style', 'overflow:hidden');
                if(b == 'content') this.auto[b].width += (parseInt(this.opt.padding, 10) || 0) * 2;
                if(this.auto[b].width > this.auto.max) this.auto.max = this.auto[b].width;
            }
        });
        t.style.float = 'left';
        c.style.float = 'right';
    },
    setDimensions:function(){
        if(!this.exec(true)) return;
        this.setAuto();
        var a = this.auto,
            d = Dialogs.view(),
            t = Dialogs.elm('content'),
            c = Dialogs.elm('container'),
            o = {
              m:((parseInt(this.opt.margin, 10) || 0) * 2),
              p:((parseInt(this.opt.padding, 10) || 0) * 2),
              t:a.top.height,
              b:a.bottom.height
            },
            m = {width:(d.width-o.m), height:(d.height-o.m-o.t-o.b)},
            h = this.opt.height,
            w = this.opt.width,
            x = false,
            y = false;
        if(typeof w == 'number')  w += o.p;
        if(w == 'max') w = m.width;
        if(typeof w != 'number') {
            w = a.max;
            var cw = c.offsetWidth + o.p; // mobile mode
            if (m.width > cw) m.width = cw;
        }
        if(w < (this.opt.minWidth || 0)) w = this.opt.minWidth || 0;
        if(w > m.width){ w = m.width; x = true }
        t.style.width = +(w-o.p)+'px';
        t.style.height = 'auto';
        if(typeof h == 'number') h += o.p;
        if(h == 'max') h = m.height;
        if(typeof h != 'number') h = t.offsetHeight+o.p;
        if(h < (this.opt.minHeight || 0)) w = this.opt.minHeight || 0;
        if(h > m.height){ h = m.height; y = true; }
        t.style.height = +(h-o.p)+'px';
        t.style.padding = +(o.p/2)+'px';
        if(this.opt.innerScroll && (x || y)) t.style.overflow = 'scroll';
        if(this.opt.innerScrollOnlyY && (x || y)) {
            t.style.overflowX = 'hidden';
            t.style.overflowY = 'scroll';
        }
        let s = {w:w,h:(h+o.t+o.b+21)};
        let ch = c.offsetHeight + o.p;  // mobile mode
        if(s.h > ch) s.h = ch;
        let mar = parseInt(s.h/2, 10)+'px 0 0 -'+parseInt(s.w/2, 10)+'px';
        let sty = 'top:50%;left:50%;margin:-'+mar;
        if ((sty != last_style) && (w != last_width + 20)) {
            last_width = w;
            last_style = sty;
            c.style.top = '50%';
            c.style.left = '50%';
            c.style.margin = '-'+mar;
        }
    },
    setScroll:function(){
        if(!this.exec(true)) return;
        const v = Dialogs.view(),
            c = Dialogs.elm('container'),
            d = {width: c.offsetWidth, height: c.offsetHeight},
            t = v.top + parseInt((v.height - d.height) / 2, 10),
            l = v.left + parseInt((v.width - d.width) / 2, 10);
        c.style.margin = '0';
        c.style.top = t;
        c.style.left = l + 'px';
        Dialogs.elm('overlay').style.margin = +v.top+'px 0 0 '+v.left+'px';
    },
    setLoad:function(){
        const l = Dialogs.elm('loading');
        l.style.display = '';
        const t = Dialogs.elm('content');
        l.id = l.id || 'dlg_loading';
        if(!t.querySelector(`#${l.id}`)) t.insertBefore(l, null);
    },
    setAjax:function(){
        this.setLoad();
        let ajax_a;
        if ( typeof this.opt.ajax === "function" ) {
            ajax_a = this.opt.ajax();
        } else {
            ajax_a = this.opt.ajax;
        }
        const o = ajax_a.options || {},
            c = (o.onComplete && typeof o.onComplete == 'function' ? o.onComplete : null),
            a = function(t){
                Dialogs.elm('content').innerHTML = t.responseText || '';
              Dialogs.elm('content').querySelectorAll('script').forEach(this.applyScript, this);
                this.setImages();
                this.setDimensions();
                if(typeof c == 'function') c(t);
            }.bind(this);
        Object.assign(o, {onComplete:a});

        fetch(ajax_a.url,
            {credentials: "same-origin"})
            .then(response => response.text())
            .then(text => {
                o.onComplete({responseText:text});
            });
    },
    setIframe:function(){
        this.setLoad();
        const f = document.createElement('iframe');
        f.src = this.opt.iframe;
        f.id = 'dialog-iframe';
        f.frameBorder = 0;
        Dialogs.elm('content').insertAdjacentElement('afterbegin', f);
        f.addEventListener('load', function() {
            Dialogs.elm('loading').style.display = 'none';
            f.style.width = '100%';
            f.style.height = '100%';
            this.setDimensions();
            if((typeof this.opt.afterIframeLoad === 'function')) this.opt.afterIframeLoad();
        }.bind(this));
    },
    setSteps:function(ev){
        if(!this.exec(true)) return;
        let m = this.steps.m,
            s = false,
            n = Dialogs.elm('next'),
            p = Dialogs.elm('prev');
        if((ev.key) == "ArrowRight" || ev.target.hasClassName('next')){
            if(this.steps.i < (m - 1)) s = true;
            if(s) ++this.steps.i;
            if(((this.steps.i + 1) >= m) && n.style.display != 'none') n.style.display = 'none';
            if(((this.steps.i - 1) >= 0) && p.style.display == 'none') p.style.display = '';
        }else{
            if(this.steps.i > 0) s = true;
            if(s) --this.steps.i;
            if(((this.steps.i - 1) < 0) && p.style.display != 'none') p.style.display = 'none';
            if(((this.steps.i + 1) <= m) && n.style.display == 'none') n.style.display = '';
        }
        if(s) this.setContent();
    },
    setContent:function(){
        const c = this.opt.content,
            t = Dialogs.elm('content');
        t.innerHTML = '';
        if(typeof c == 'string') {
            t.innerHTML = c;
          t.querySelectorAll('script').forEach(this.applyScript, this);
        } else if(c instanceof HTMLElement) t.insertBefore(c, null);
        else if(Array.isArray(c)) c.forEach(e => {
            if(typeof e == 'string'){
                let div = document.createElement('span');
                div.innerHTML = e;
                e = div;
            }
            t.insertBefore(e, null);
        });
        else if( c && !Array.isArray(c) && !(c instanceof HTMLElement) && typeof c == 'object'){
            var b = Dialogs.elm('bottom');
            t.innerHTML = '';
            t.insertBefore(this.steps.v[this.steps.i], null)
            Dialogs.elm('curr').innerHTML = this.steps.k[this.steps.i];
            if(b.style.display == 'none'){
                b.style.display = '';
                b.children.forEach(e => {
                    e.style.display = '';
                });
            }
            if(this.steps.i <= 0) Dialogs.elm('prev').style.display = 'none';
            if(this.steps.i >= (this.steps.m - 1)) Dialogs.elm('next').style.display = 'none';
        }else if(typeof this.opt.ajax.url == 'string' || typeof this.opt.ajax == 'function' ) this.setAjax();
        else if(typeof this.opt.iframe == 'string') this.setIframe();
        this.setImages();
        setTimeout(this.setDimensions.bind(this), 0);
    },
    setImages:function(){
        Dialogs.elm('content').querySelectorAll('img').forEach(el =>{
            el.onload = () => {
                this.setDimensions();
            }
        });
    },
    open:function(){

        if(typeof this.opt.title == 'string' || this.opt.close.link){
            if(typeof this.opt.title == 'string'){
                Dialogs.elm('title').style.display = '';
                Dialogs.elm('title').innerHTML = this.opt.title;
            }
            if(this.opt.close.link) Dialogs.elm('close').style.display = '';
            else Dialogs.elm('close').style.display = 'none';
            Dialogs.elm('top').style.display = '';
        }else Dialogs.elm('top').style.display = 'none';

        const o = Dialogs.elm('overlay'), c = Dialogs.elm('container'), t = Dialogs.elm('content');
        [o, c, t].forEach(e => { e.style.display = '' });
        o.style.opacity = this.opt.opacity || 1;
        o.style.background = this.opt.background[0] || '#000';
        c.setAttribute('style', 'left:-99999px;top:-99999px;background:'+(this.opt.background[1] || '#fff'));
        t.class = this.opt.className || '';
        if(this.opt.topClassName) Dialogs.elm('top').classList.add( this.opt.topClassName);
        Dialogs._open = new Date().getTime();
        this._open = Dialogs._open;
        this.setContent();
        if(typeof this.opt.afterOpen == 'function') this.opt.afterOpen(this);
    },
    close:function(){
        Dialogs.close();
        if(typeof this.opt.afterClose == 'function') this.opt.afterClose();
    },
    hideTitleBar:function(){
        Dialogs.elm('container').classList.add('noTitleBar');
    },
    applyScript:function(scriptlet){
      if (scriptlet.src) {
        setTimeout(this.loadScript(scriptlet.src), 0);
      } else {
        eval(scriptlet.innerHTML); // eslint-disable-line no-eval
      }
    },
    loadScript:function(src){
      let script = document.createElement('script');
      script.src = src;
      document.head.appendChild(script);
    }
};
export {Dialog, Dialogs};
